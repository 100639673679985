
import { defineComponent, onMounted, ref } from 'vue'
import { getVersionList, getVersionCompareList } from '@/API/finance'
import blobDownload from '@/utils/blobDownload'
import { columnsFinan, columnsFl, columnsEq, columnsEqfl } from './data'
import { ColmunsProps,  } from '@/API/types'
import { useRoute } from 'vue-router';

import moment from "moment";

const columns = [
    {
        title: 'Version History',
        dataIndex: 'programVersion',
        key: 'programVersion'
    },
    {
        title: 'Modified By',
        dataIndex: 'createUser',
        key: 'createUser'
    },
    {
        title: 'Modified On',
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        slots: {customRender: 'timestemp'}
    }
]

interface VersionParams {
    typeClass: string;
    modelYear: string;
    sheetName: string;
    brand: string;
    versionList: string[];
}
const versionKey = {
    'Financing': 'calculatorCompareDtos',
    'FL with Balloon': 'calculatorFLCompareDtos',
    'NEV Agility': 'calculatorEQCCompareDtos',
    'NEV FL with Balloon': 'calculatorEQCCompareDtos'
}
export default defineComponent({
    name: '',
    setup() {
        // sheet 页的数据
        // 导航的数据
        const sheetArr = ref(['Financing', 'FL with Balloon', 'NEV Agility', 'NEV FL with Balloon'])
        const activeKey = ref()
        const route = useRoute()
        // 表格的数据
        const dataSource = ref()
        const getTable = (sheetName = 'Financing') => {
            
            const params = {
                programId: route.query.pgmId
            }
            getVersionList({params}).then((res: any) => {
                dataSource.value = res[sheetName]
            })    
        }
        
        // 配置选择框
        const selectedRowKeys = ref<string[]>([])
        const onSelectChange = (selected: string[]) => {
            selectedRowKeys.value = selected
        }

        const init = async() => {
            // 获取sheet页数据
            // sheetArr.value = await getSheetAllAtfasetting()
            activeKey.value = sheetArr.value[0]
            getTable()
        }

        const columnsVersion = ref<ColmunsProps[]>([])
        
        const configColumns = (data: any) => {
            columnsVersion.value = []
            data.forEach((item: {}) => {
                for (const key in item) {
                    columnsVersion.value.push({
                        title: item[key],
                        dataIndex: key
                    })
                }
            })
        }

        // 给columnsV 赋值
        const assignColumns = () => {
            if (activeKey.value === 'Financing') {
                columnsVersion.value = columnsFinan
            } else if (activeKey.value === 'FL with Balloon') {
                columnsVersion.value = columnsFl
            } else if (activeKey.value === 'NEV Agility') {
                columnsVersion.value = columnsEq
            } else if (activeKey.value === 'NEV FL with Balloon') {
                columnsVersion.value = columnsEqfl 
            } 
        }

        const verCompareList = ref()
        const handleSearch = () => {
            const {pgmId: programId, bu} = route.query
            const arr = dataSource.value.filter((item: any) => (selectedRowKeys.value).includes(item.programVersion))
            getVersionCompareList(programId as string, bu as string ,arr).then((res: any) => {
                verCompareList.value = res
                if (res[0].title.length === 0) {
                    assignColumns()
                } else {
                    configColumns(res[0].title)
                }
            })
        }

        // 获取全部的table数据
        const handleExtendAll = () => {
            assignColumns()
        }

        // 下载当前勾选的版本数据
        const handleDownload = () => {
            const arr = dataSource.value.filter((item: any) => (selectedRowKeys.value).includes(item.programVersion))
            arr.forEach((item: any) => {
                const params = { 
                    url: `/pgapi/prog/setting/fa/downloadFile`,
                    method: 'post',
                    params: item
                }
                blobDownload(params)
            })
        }

         // 切换sheet
        const cutTabs = (sheetName: string) => {
            getTable(sheetName)
            verCompareList.value = []
            selectedRowKeys.value = []
        }
        onMounted(() => {
            init()
        })
        return {
            sheetArr,
            activeKey,
            cutTabs,

            columns,
            dataSource,
            //  rowSelection,
            selectedRowKeys,
            onSelectChange,

            handleSearch,
            verCompareList,

            columnsVersion,
            handleDownload,
            handleExtendAll,
            moment,
            versionKey


        }

    }
 });
