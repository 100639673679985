import deepClone from "@/utils/deepClone";
const columns = [
  {
    title: "Type Class",
    dataIndex: "typeClassName",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Type Class Code",
    dataIndex: "typeClassCode",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Model Year",
    dataIndex: "modelYear",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Type",
    dataIndex: "type",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Nst Group Name",
    dataIndex: "nstGroupName",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Model",
    dataIndex: "model",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "MSRP",
    dataIndex: "msrp",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Transaction Price",
    dataIndex: "transactionPrice",
    slots: { customRender: "showText" },
    width: 130,
  },

  // EQC 有个不一样的
  {
    title: "Service Package",
    dataIndex: "servicePackage",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Term",
    dataIndex: "term",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Bill Rate",
    dataIndex: "billRate",
    slots: { customRender: "showText" },
    width: 130,
  },
  // EQC 有 3 个不一样的 、 fl finan 有个 customer Rate
  // if (agilty) RV RV Amount / BAll
];

const columnsFlarr = [
  {
    title: "Customer Rate",
    dataIndex: "customerRate",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Pre-payment%",
    dataIndex: "prePaymentPer",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "RV%",
    dataIndex: "rvPer",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Balloon%",
    dataIndex: "balloonPer",
    slots: { customRender: "showText" },
    width: 130,
  },
];

const columnsEnd = [
  {
    title: "DP Amount",
    dataIndex: "dpAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Loan Amount",
    dataIndex: "loanAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Balloon Amount",
    dataIndex: "balloonAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Monthly Payment",
    dataIndex: "monthlyPayment",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Subsidy to MSRP/1.13",
    dataIndex: "subsidyToMsrpDivideOnedotthirteen",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Total Subsidy Amount",
    dataIndex: "totalSubsidyAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Product Mix",
    dataIndex: "productMix",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Rounding subsidy",
    dataIndex: "roundingSubsidy",
    slots: { customRender: "showText" },
    width: 130,
  },
];

const finanArrCopy = deepClone(columnsFlarr);
finanArrCopy.splice(1, 2, {
  title: "Down payment%",
  dataIndex: "downPaymentPer",
  slots: { customRender: "showText" },
  width: 130,
});
// Financing
let columnsFinan = finanArrCopy;
columnsFinan = columns.concat(columnsFinan).concat(columnsEnd);

// FL with balloon
const columnsFl = columns.concat(columnsFlarr).concat(columnsEnd);
columnsFl.push({
  title: "Daily Pay",
  dataIndex: "dailyPay",
  slots: { customRender: "showText" },
  width: 130,
});

const columnsEqcEnd = [
  {
    title: "Customer Rate Before DBD",
    dataIndex: "customerRateDeforeDbd",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Customer Rate After DBD",
    dataIndex: "customerRateAfterDbd",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Pre-payment%",
    dataIndex: "prePaymentPer",
    slots: { customRender: "showText" },
    width: 130,
  },
  // dddd
  {
    title: "RV%",
    dataIndex: "rvPer",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "RV Amount",
    dataIndex: "rvAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  //
  {
    title: "DP Amount",
    dataIndex: "dpAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Loan Amount",
    dataIndex: "loanAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Monthly Payment Before DBD",
    dataIndex: "monthlyPaymentBeforeDbd",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Monthly Payment Before DBD",
    dataIndex: "monthlyPaymentAfterDbd",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Subsidy to MSRP/1.13",
    dataIndex: "subsidyToMsrpDivideOnedotthirteen",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Subsidy to Amount By OEM",
    dataIndex: "subsidyAmountDyOem",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Subsidy Amount By Dealer",
    dataIndex: "subsidyAmountDyDealer",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Total Subsidy Amount",
    dataIndex: "totalSubsidyAmount",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Dealer Subsidy Portion",
    dataIndex: "dealerSubsidyPortion",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Product Mix",
    dataIndex: "productMix",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "OEM Rounding subsidy",
    dataIndex: "oemRoundingSubsidy",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Faily Pay",
    dataIndex: "dailyPay",
    slots: { customRender: "showText" },
    width: 130,
  },
];

// EQC Agility
const columnsCopy = deepClone(columns);
columnsCopy.splice(7, 0, {
  title: "Net Vehicle Price",
  dataIndex: "netVehiclePrice",
  slots: { customRender: "showText" },
  width: 130,
});
const columnsEq = columnsCopy.concat(columnsEqcEnd);

const columnsEqCopy = deepClone(columnsEqcEnd);
columnsEqCopy.splice(
  3,
  2,
  {
    title: "Balloon%",
    dataIndex: "balloonPer",
    slots: { customRender: "showText" },
    width: 130,
  },
  {
    title: "Balloon Amount",
    dataIndex: "balloonAmount",
    slots: { customRender: "showText" },
    width: 130,
  }
);
const columnsEqfl = columnsCopy.concat(columnsEqCopy);
export { columnsFinan, columnsFl, columnsEq, columnsEqfl };
